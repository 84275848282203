@font-face {
  font-family: Bolton;
  src: url("F37Bolton-Regular.002e3e7a.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Bolton;
  src: url("F37Bolton-Bold.1c7757be.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Bolton;
  src: url("F37Bolton-BoldItalic.bd2c4471.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Bolton;
  src: url("F37Bolton-Italic.9ff955ed.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Bolton;
  src: url("F37Bolton-Thin.52d5e347.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Bolton;
  src: url("F37Bolton-ThinItalic.727fac4b.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}
/*# sourceMappingURL=about.12a3eeba.css.map */
