@font-face {
    font-family: 'Bolton';
    src: url('../fonts/Bolton/F37Bolton-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Bolton';
    src: url('../fonts/Bolton/F37Bolton-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Bolton';
    src: url('../fonts/Bolton/F37Bolton-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Bolton';
    src: url('../fonts/Bolton/F37Bolton-Italic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Bolton';
    src: url('../fonts/Bolton/F37Bolton-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Bolton';
    src: url('../fonts/Bolton/F37Bolton-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}